import React, { useState } from "react";
import PropTypes, { node } from "prop-types";
import { graphql, navigate } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ReactMarkdown from "react-markdown";

const IndexPage = ({ data, location }) => {
  const [dsgvo, setSeen] = useState(true);
  
  const {
    markdownRemark: {
      frontmatter: { image, text, title, color },
    },
  } = data;

  return (
    <div className="h-screen overflow-auto w-screen relative">
      <main className={`grid`}>
        <div
          onClick={() => {
            if (!dsgvo) return;
            navigate("/works");
          }}
          className="grid grid-cols-12"
        >
          <div className="col-span-12 grid ">
            <div
              className="pt-16 z-10 text-5xl px-4 cursor-pointer"
              style={{ color }}
            >
              {title}
            </div>
            <div className="h-auto z-10">
              <ReactMarkdown
                className={`pt-20 text-5xl px-4 ${!dsgvo ? "blur-sm" : ""}`}
                style={{ color }}
                components={{
                  p: ({ node, ...props }) => (
                    <div style={{ color }} {...props} />
                  ),
                  a: ({ node, ...props }) => (
                    <a
                      className="z-10"
                      href={props.href}
                      target="_blank"
                      disabled={!dsgvo}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      {...props}
                    />
                  ),
                }}
                children={text}
              />
            </div>
          </div>
        </div>
      </main>
      <PreviewCompatibleImage
        styles={{ position: "absolute", top: 0, left: 0, right: 0 }}
        imageInfo={{ image }}
      />
      {/* {!dsgvo && (
        <div className="fixed left-0 right-0 bottom-0 bg-gray-300 h-60 z-20 p-10">
          <p>I accept the privacy</p>
          <div onClick={() => setSeen(true)} className="button rounded px-4 py-2 border-blue-300 border inline-block">Accept</div>
        </div>
      )} */}
    </div>
  );
};

IndexPage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        color
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
        text
      }
    }
  }
`;
